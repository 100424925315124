import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../../components/Menu";

import GalleryImage from "../../components/GalleryImage";
import Footer from "../../components/Footer";
import Card from "../../components/Card";

import im1 from "../../images/collections/distortion-reality/p1.jpg"
import im2 from "../../images/collections/distortion-reality/p2.jpg"
import im3 from "../../images/collections/distortion-reality/p3.jpg"
import im4 from "../../images/collections/distortion-reality/p4.jpg"
import im5 from "../../images/collections/distortion-reality/p5.jpg"
import im6 from "../../images/collections/distortion-reality/p6.jpg"
import im7 from "../../images/collections/distortion-reality/p7.jpg"
import im8 from "../../images/collections/distortion-reality/p8.jpg"
import im9 from "../../images/collections/distortion-reality/p9.jpg"
import im10 from "../../images/collections/distortion-reality/p10.jpg"
import im11 from "../../images/collections/distortion-reality/p11.jpg"
import im12 from "../../images/collections/distortion-reality/p12.jpg"
import im13 from "../../images/collections/distortion-reality/p13.jpg"
import monalisa from "../../images/collections/distortion-reality/monalisa.jpg"

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
        <main className="flex flex-wrap flex-row mt-24">
            <Card centered backgroundImage={monalisa} />
            <Card centered height=" ">
                <h1 className="font-superclarendon text-4xl mb-6">
                    The Distortion of Reality
                </h1>
                <p>Deze eerste collectie is geïnspireerd op een gedachte-experiment waarin de verschillende wereldbeelden van alle mensen op aarde bij elkaar worden gevoegd.</p>
                <p>In dit experiment is elk wereldbeeld even waar. Maar daardoor sluiten verschillende wereldbeelden elkaar uit.</p>
                <p>Er ontstaat een glitch. Alles is waar en alles is een leugen. Jouw realiteit is mijn complot en niets is wat het lijkt.</p>
            </Card>

            <section className="overflow-hidden bg-secondary">
                <div className="w-full px-5 py-2 mx-auto lg:py-12 lg:px-32">
                <div className="flex flex-wrap -m-1 md:-m-2">
                    <GalleryImage src={im1} />
                    <GalleryImage src={im2} />
                    <GalleryImage src={im3} />
                    <GalleryImage src={im4} />
                    <GalleryImage src={im5} />
                    <GalleryImage src={im6} />
                    <GalleryImage src={im7} />
                    <GalleryImage src={im8} />
                    <GalleryImage src={im9} />
                    <GalleryImage src={im10} />
                    <GalleryImage src={im11} />
                    <GalleryImage src={im12} />
                    <GalleryImage src={im13} />
                </div>
                </div>
            </section>
        </main>

      <Footer />
    </div>
  );
}

export default Index
